import React from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import { SectionHeading } from "components/misc/Headings";

const HeadingRow = tw.div`flex`;
const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;
export default ({ headingText = "Privacy Policy" }) => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Privacy Policy - Core Dreams Ltd</title>
        <meta
          name="description"
          content="Core Dreams Ltd will not collect any personal information from anyone we know to be under the age of 13 without the prior information"
        />
      </Helmet>
      <Header />
      <Container className="terms-wrapper">
        <ContentWithPaddingXl>
          <HeadingRow>
            <Heading>{headingText}</Heading>
          </HeadingRow>
          <div className="row">
            <div className="col-md-3">
              <h4>Terms of Services</h4>
            </div>
            <div className="col-md-9">
              <p>
                This page is used to inform website visitors regarding our
                policies with the collection, use, and disclosure of Personal
                Information if anyone decided to use our Service, the
                coredreamsltd.com
              </p>

              <p>
                Core Dreams Innovation Pvt. Ltd. operates the Taxi-Webdesign.com
                website, which provides the services. Every project for Web, APP
                & Digital Marketing project is accomplished and delivered by
                Core Dreams Innovation Pvt Ltd. The terms used in this Privacy
                Policy have the same meaning as in our Terms and Conditions,
                which is accessible at Taxi-Webdesign.com unless otherwise
                defined in this Privacy Policy.
              </p>

              <p>
                If you choose to use our Service, then you agree to the
                collection and use of information concerning this policy. The
                Personal Information that we collect is used for providing and
                improving the Service. We will not use or share your information
                with anyone except as described in this Privacy Policy.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Information Collection and Use </h4>
            </div>
            <div className="col-md-9">
              <p>
                The information that we collect will be used to contact and
                identify you for past, ongoing services that you have signed up
                with us. Information submitted on our website will be used for
                our marketing and upcoming promotion purpose as well.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Cookies</h4>
            </div>
            <div className="col-md-9">
              <p>
                Cookies are files with a small amount of data that is commonly
                used as an anonymous unique identifier. These are sent to your
                browser from the website that you visit and are stored on your
                computer’s hard drive.
              </p>

              <p>
                Our website uses these “cookies” to collect information and to
                improve our service. You have the option to either accept or
                refuse these cookiesand know when a cookie is being sent to your
                computer. If you choose to refuse our cookies, you may not be
                able to use some portions of our Service. Cookies help us
                provide you with a better website, by enabling us to monitor
                which pages you find useful and which you do not. A cookie in no
                way gives us access to your computer or any information about
                you, other than the data you choose to share with us.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Service Providers</h4>
            </div>
            <div className="col-md-9">
              <p>
                We may employ third-party companies and individuals due to the
                following reasons:
              </p>

              <ul type="disc">
                <li>To facilitate our service.</li>
                <li>To provide the Service on our behalf.</li>
                <li>To perform Service-related services; or</li>
                <li>To assist us in analyzing how our service is used.</li>
              </ul>

              <p>
                We want to inform our Service users that these third parties
                have access to their Personal Information. The reason is to
                perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any
                other purpose.
              </p>

              <p>
                Please be aware that www.coredreamsltd.com does not control and
                is not responsible for websites that are referred to or linked
                from its websites and that use of your personal information on
                these websites is not subject to this Privacy and Cookies
                Policy.
              </p>

              <p>
                You are reminded that in addition to the provisions of this
                Privacy Policy, where you register you also agree to:
              </p>

              <ul type="disc">
                <li>
                  Provide true, accurate, current, and complete information
                  about yourself as prompted by the relevant registration form
                  (such information being the “Registration Data”); and maintain
                  and promptly update the Registration Data to keep it fully up
                  to date.
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Security</h4>
            </div>
            <div className="col-md-9">
              <p>
                We value your trust in providing us with your Personal
                Information, thus we are striving to use commercially acceptable
                means of protecting it. But remember that no method of
                transmission over the internet, or method of electronic storage
                is 100% secure and reliable, and we cannot guarantee its
                absolute security.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <h4>Changes to This Privacy Policy</h4>
            </div>
            <div className="col-md-9">
              <p>
                We may update our Privacy Policy from time to time. Thus, we
                advise you to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page. These changes are effective immediately after they
                are posted on this page.
              </p>
            </div>
          </div>
        </ContentWithPaddingXl>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
