import React from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";

import CareerBanner from "images/career.svg";
import WhyJoin from "images/career2.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>
          Career for Web designer & App developer | Core Dreams Innovations
        </title>
        <meta
          name="description"
          content="Job list for Web Designer and App Developer jobs and vacancies in Core Dreams Innovations. Find a list of jobs in Web Designing, Front End Developer and apply online to boost your career"
        />
      </Helmet>
      <Header />
      <section className="career-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="inner-main-heading text-left">
                Join Our Team <span>Career Growth</span>
              </h1>
              <p>
                For continuous growth, we believe in teamwork and taking on
                challenging projects. If you are committed, dedicated, and
                hardworking, and looking for Career and Knowledge Growth, then
                you might be the right candidate.
              </p>
            </div>
            <div className="col-md-6">
              <img src={CareerBanner} />
            </div>
          </div>
          <div className="row pb-5 mb-5">
            <div className="col-md-6">
              <img src={WhyJoin} />
            </div>
            <div className="col-md-6 pt-5">
              <h2 className="inner-sm-header  pt-5 text-left">Why Join Us</h2>
              <p>
                Coredreamsltd offers you a world of potential.We ​​​encourage
                our people to dive in, roll up their sleeves and take on the
                many opportunities bound to come their way.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="hav-banner career-list section-gap">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pr-4 d-flex j">
              <div>
                <h3 className="inner-sm-header">
                  Recent <br /> Vacancies
                </h3>
                <p>
                  We invest in our employees to help them develop and achieve
                  their professional goals.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <ul className="list-unstyled">
                <li>
                  <h4>
                    Sr. iOS Developer
                    <a
                      target="_blank"
                      className="btn btn-red float-right"
                      href="https://recruit.zoho.com/recruit/ViewJob.na?digest=1vrHxKA063K2ENV9VERC@IJkhDPPDrC6dejhAW0heDg-&embedsource=CareerSite"
                    >
                      Apply Now
                    </a>
                  </h4>
                </li>
                <li>
                  <h4>
                    Sr. React Native Developer
                    <a
                      target="_blank"
                      className="btn btn-red float-right"
                      href="https://recruit.zoho.com/recruit/ViewJob.na?digest=1vrHxKA063K2ENV9VERC@FPtUIvezyWCQWMMvrBUWlA-&embedsource=CareerSite"
                    >
                      Apply Now
                    </a>
                  </h4>
                </li>
                <li>
                  <h4>
                    Sr. Android Developer
                    <a
                      target="_blank"
                      className="btn btn-red float-right"
                      href="https://recruit.zoho.com/recruit/ViewJob.na?digest=1vrHxKA063K2ENV9VERC@LO.YzQis7vZhiYez@9afws-&embedsource=CareerSite"
                    >
                      Apply Now
                    </a>
                  </h4>
                </li>
                <li>
                  <h4>
                    Jr. React Native Developer
                    <a
                      target="_blank"
                      className="btn btn-red float-right"
                      href="https://recruit.zohopublic.com/recruit/ViewJob.na?digest=1vrHxKA063K2ENV9VERC%40OH0f62Te81Ggu7n8D7sF58-&embedsource=CareerSite"
                    >
                      Apply Now
                    </a>
                  </h4>
                </li>
                <li>
                  <h4>
                    Laravel Developer
                    <a
                      target="_blank"
                      className="btn btn-red float-right "
                      href="https://recruit.zoho.com/recruit/ViewJob.na?digest=1vrHxKA063K2ENV9VERC@P.zctCo6eU5RJHtmaL5tAU-&embedsource=CareerSite"
                    >
                      Apply Now
                    </a>
                  </h4>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </AnimationRevealPage>
  );
};
