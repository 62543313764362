import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { ReactComponent as ChevronDownIcon } from "feather-icons/dist/icons/chevron-down.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`mb-4 text-center`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const Column = tw.div`flex flex-col items-center`;
const HeaderContent = tw.div``;

const FAQSContainer = tw.dl`mt-12 max-w-4xl relative`;
const FAQ = tw.div`cursor-pointer select-none mt-5 px-8 sm:px-10 py-5 sm:py-4 rounded-lg text-gray-800 hover:text-gray-900 bg-gray-200 hover:bg-gray-300 transition duration-300`;
const Question = tw.dt`flex justify-between items-center`;
const QuestionText = tw.span`text-lg lg:text-xl font-semibold`;
const QuestionToggleIcon = motion(styled.span`
  ${tw`ml-2 transition duration-300`}
  svg {
    ${tw`w-6 h-6`}
  }
`);
const Answer = motion(tw.dd`pointer-events-none text-sm sm:text-base leading-relaxed`);

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute right-0 top-0 h-56 w-56 opacity-15 transform translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute left-0 bottom-0 h-64 w-64 opacity-15 transform -translate-x-2/3 text-primary-500`}
`;



export default ({
  heading = "You have Questions ?",
  description = "Our FAQ is the best ways to help you when visiting or using our site",
  faqs = [
    {
      question: "Why Should we use Core Dreams over another agency?",
      answer:
        "We have over 10 years of experience in developing all kinds of transfer apps such as Taxi App, Limo App, and bringing systems together with seamless integration.Our Client use us because of our broad experience with the app and website development for transport business category"
    },
    {
      question: "I have got an idea, where do I start?",
      answer:
        "If you have any idea, you can share your idea to info@coredreamsltd.com,one of our team will get back to you within 1 working by email or phone. If you'd prefer to speak to us right now,call +1 718 618 4363,one of our team will talk to your regarding your ideas and project.We never hard sell, all calls are without obligations, and we’re always happy to help. "
    },
    {
      question: "Do you build apps for both iOS and Android?",
      answer:
        "Yes, we build apps for both platforms your kind of business.We use development frameworks such as React and React native that enable us to quickly support both platforms."
    },
    {
      question: "What kind of support will I receive throughout the development process?",
      answer:
        "Once you sign up for the project, we will assign a dedicated team of developers and designers to your project as primary contact throughout the development process"
    },
    {
      question: "Will i own the source code?",
      answer:
        "Yes, you will be assigned appropriate copyright or license rights for the bespoke code that we write for your project. We are transparent with intellectual property rights, and you will have the opportunity to review our code ownership terms after the project completes."
    },
    {
      question: "How long will it take to build and launch my app?",
      answer:
        "Many factors contribute to launching a successful project, and some requirements may result in the project taking longer to develop than others. However, for estimation, a good guide is that a project will typically take 3-4 months for every $8,000 of development work."
    },
    {
      question: "Can you deliver my project quicker?",
      answer:
        "In Some cases yes, in others, no. If you have a date requirement, contact us as soon as possible so we can do our very best to meet it."
    },
    {
      question: "Where can I reach you for support",
      answer:
        "Either you can email me at info@taxi-webdesign.com or call +1718 618 4363"
    }
  ]
}) => {
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);

  const toggleQuestion = questionIndex => {
    if (activeQuestionIndex === questionIndex) setActiveQuestionIndex(null);
    else setActiveQuestionIndex(questionIndex);
  };

  return (
    <Container className="faq-wrapper">
      <ContentWithPaddingXl>
        <Column>
          <HeaderContent>
 
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
          </HeaderContent>
          <FAQSContainer>
            {faqs.map((faq, index) => (
              <FAQ
                key={index}
                onClick={() => {
                  toggleQuestion(index);
                }}
                className="group"
              >
                <Question>
                  <QuestionText>{faq.question}</QuestionText>
                  <QuestionToggleIcon
                    variants={{
                      collapsed: { rotate: 0 },
                      open: { rotate: -180 }
                    }}
                    initial="collapsed"
                    animate={activeQuestionIndex === index ? "open" : "collapsed"}
                    transition={{ duration: 0.02, ease: [0.04, 0.62, 0.23, 0.98] }}
                  >
                    <ChevronDownIcon />
                  </QuestionToggleIcon>
                </Question>
                <Answer
                  variants={{
                    open: { opacity: 1, height: "auto", marginTop: "16px" },
                    collapsed: { opacity: 0, height: 0, marginTop: "0px" }
                  }}
                  initial="collapsed"
                  animate={activeQuestionIndex === index ? "open" : "collapsed"}
                  transition={{ duration: 0.3, ease: [0.04, 0.62, 0.23, 0.98] }}
                >
                  {faq.answer}
                </Answer>
              </FAQ>
            ))}
          </FAQSContainer>
        </Column>
      </ContentWithPaddingXl>
      <DecoratorBlob1/>
      <DecoratorBlob2 />
    </Container>
  );
};
