import React from "react";
import { Helmet } from "react-helmet";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import FrontendImage from "images/front-end.svg";
import BackendImage from "images/backend.svg";
import HybridImage from "images/hybrid-app.svg";
import Document from "images/writing.svg";
import WireFrame from "images/wire-frame.svg";
import Collection from "images/hosting.svg";
import FrontDevelopment from "images/ux-interface.svg";
import Development from "images/developer.svg";
import Testing from "images/test.svg";
import Production from "images/cloud-computing.svg";
import WhyBanner from "images/why-us.svg";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage disabled>
      <Helmet> 
        <title>
          Outsourcing Web & App Development Company - Core Dreams Innovations
        </title>
        <meta
          name="description"
          content="Core Dreams Innovations is a leading outsourcing web design & App Development company, We deliver high-quality services including web design, web development, app development, and digital marketing for the transport business category"
        />
      </Helmet>
      <Hero roundedHeaderButton={true} />
      <section className="hav-banner hp-we-works section-gap">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <img src={WhyBanner} />
            </div>
            <div className="col-md-6">
              <h3>Our Working Pattern</h3>
              <p>
                <img className="icons" src={Collection} /> Resource Collection
              </p>
              <p>
                <img className="icons" src={Document} /> Documentation
              </p>
              <p>
                <img className="icons" src={WireFrame} /> Wireframe& Prototype
              </p>
              <p>
                <img className="icons" src={FrontDevelopment} /> Front end
                Development
              </p>
              <p>
                <img className="icons" src={Development} /> Back-end Development
              </p>
              <p>
                <img className="icons" src={Testing} /> Testing and Debugging
              </p>
              <p>
                <img className="icons" src={Production} /> Production
              </p>
            </div>
          </div>
        </div>
      </section>

      <MainFeature
      id={"frontend"}
        heading={"Front End Development"}
        list1={"Angular"}
        list2={"React"}
        list3={"Javascript"}
        list4={"CSS"}
        list5={"HTML"}
        list6={"SASS"}
        imageSrc={FrontendImage}
        textOnLeft={true}
      />
      <MainFeature
        id={"backend"}
        heading={"Back End Development"}
        list1={"php"}
        list2={"node Js"}
        list3={"Codeigniter"}
        list4={"Laravel"}
        imageSrc={BackendImage}
        textOnLeft={false}
      />
      <MainFeature
        id={"app"}
        heading={"Mobile App - Hybrid & Native"}
        list1={"IOS"}
        list2={"Android"}
        list3={"React Native"}
        list4={"Kotlin"}
        list5={"Angular"}
        list6={"Java"}
        imageSrc={HybridImage}
        textOnLeft={true}
      />

      <Testimonial
        heading={<>Our Clients</>}
        testimonials={[
          {
            stars: 5,
            heading: "Great Service",
            quote:
              "Great Service. Helped a lot for my website. Definitely would recommend to others!!",
            customerName: "Selliah Wigneswaran",
          },
          {
            stars: 4,
            heading: "I have been using them for over 5 year",
            quote:
              "I have been using them for over 5 year. They have excellent understanding of technology required to do the job. They have a yes attitude and to top it up they always thoroughly understand the business model to make the technology work. Will recommend them.",
            customerName: "Abdullah Malik",
          },
          {
            stars: 5,
            heading: "Please. with the site.",
            quote:
              " Hi, very professional website builder. I’ve my latest site builded my them and I’m very please with It. I would recommend them, very good thanks.",
            customerName: "Jj appleby",
          },
          {
            stars: 5,
            heading: "Perfect Website, they help my succeed",
            quote:
              "They build my website I really love, also when something needs to be done or changed they always help you very fast. I run a bussiness so speed is very important. I give them 10/10.",
            customerName: "Nordin Rhninou",
          },
          {
            stars: 5,
            heading: "Sunil and his team at Taxiweb Design…",
            quote:
              " Sunil and his team at Taxiweb Design built a great taxi booking website for me. I'm impressed by how good they translated my vision into reality at the end creating a beautiful website! In my experience working with them, they were professional, prompt in responding and affordable.         Ali rideonemn.com",
          },
          {
            customerName: "Carresa Group",
            stars: 5,
            heading: "Thank you team taxi web design for your…",
            quote:
              "Thank you team taxi web design for your service and for your support",
          },
          {
            customerName: "Othman Kadiri",
            stars: 5,
            heading: "Very reactive team",
            quote:
              "Very reactive team. We worked on a bus company website with Sunil and Anil and everything went very smoothly. A very professional team, friendly and always reactive to fix any problem. I recommend !",
          },
          {
            customerName: "Chi",
            stars: 5,
            heading: "Very professional, and awesome to work with.",
            quote:
              "I have been working closely with Sunil and his guys since January 2020 to design a custom web website and a booking system for my car service business website. I must tell you, these guys understand web development, and are very patient with their customers. They have done a wonderful job, and I will be using them for every web development project that I can not do myself. Thank you guys!",
          },
        ]}
      />
      <FAQ
        heading={<>You have Questions ?</>}
        faqs={[
          {
            question: "Why Should we use Core Dreams over another agency?",
            answer:
              "We have over 10 years of experience in developing all kinds of transfer apps such as Taxi App, Limo App, and bringing systems together with seamless integration.Our Client use us because of our broad experience with the app and website development for transport business category",
          },
          {
            question: "I have got an idea, where do I start?",
            answer:
              "If you have any idea, you can share your idea to info@coredreamsltd.com,one of our team will get back to you within 1 working by email or phone. If you'd prefer to speak to us right now,call +1 718 618 4363,one of our team will talk to your regarding your ideas and project.We never hard sell, all calls are without obligations, and we’re always happy to help. ",
          },

          {
            question:
              "What kind of support will I receive throughout the development process?",
            answer:
              "Once you sign up for the project, we will assign a dedicated team of developers and designers to your project as primary contact throughout the development process",
          },
          {
            question: "Will i own the source code?",
            answer:
              "Yes, you will be assigned appropriate copyright or license rights for the bespoke code that we write for your project. We are transparent with intellectual property rights, and you will have the opportunity to review our code ownership terms after the project completes.",
          },
          {
            question: "How long will it take to build and launch my app?",
            answer:
              "Many factors contribute to launching a successful project, and some requirements may result in the project taking longer to develop than others. However, for estimation, a good guide is that a project will typically take 3-4 months for every $8,000 of development work.",
          },
          {
            question: "Can you deliver my project quicker?",
            answer:
              "In Some cases yes, in others, no. If you have a date requirement, contact us as soon as possible so we can do our very best to meet it.",
          },
          {
            question: "Where can I reach you for support",
            answer:
              "Either you can email me at info@coredreamsltd.com or call +1718 618 4363",
          },
        ]}
      />
      <GetStarted />
      <Footer />
    </AnimationRevealPage>
  );
};
