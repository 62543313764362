import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import "styles/master.css";
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { css } from "styled-components/macro"; //eslint-disable-line

import SaaSProductLandingPage from "demos/SaaSProductLandingPage.js";
import AboutUsPage from "pages/AboutUs.js";
import CareerPage from "pages/career.js";
import ContactUsPage from "pages/ContactUs.js";
import TermsOfServicePage from "pages/TermsOfService.js";
import PrivacyPolicyPage from "pages/PrivacyPolicy.js";

import ComponentRenderer from "ComponentRenderer.js";
import ThankYouPage from "ThankYouPage.js";


export default function App() {


    return ( <
        Router >
        <
        Switch >
        <
        Route path = "/components/:type/:subtype/:name" >
        <
        ComponentRenderer / >
        <
        /Route> <
        Route path = "/components/:type/:name" >
        <
        ComponentRenderer / >
        <
        /Route> <
        Route path = "/thank-you" >
        <
        ThankYouPage / >
        <
        /Route> <
        Route path = "/about" >
        <
        AboutUsPage / >
        <
        /Route> <
        Route path = "/career" >
        <
        CareerPage / >
        <
        /Route> <
        Route path = "/contact" >
        <
        ContactUsPage / >
        <
        /Route> <
        Route path = "/privacy-policy" >
        <
        PrivacyPolicyPage / >
        <
        /Route> <
        Route path = "/terms-conditions" >
        <
        TermsOfServicePage / >
        <
        /Route> <
        Route path = "/" >
        <
        SaaSProductLandingPage / >
        <
        /Route> <
        /Switch> <
        /Router>
    );
}