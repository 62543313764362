import React from "react";
import tw from "twin.macro";
import styled from "styled-components";

import threeLogoImage from "images/360logo.png";
import taxiLogoImage from "images/taxilogo.png";

import { ReactComponent as FacebookIcon } from "images/facebook-logo.svg";
import { ReactComponent as LinkedInIcon } from "images/linkedin.svg";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoImg = tw.img`w-8`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full  text-gray-100   transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container className="footer">
      <div className="dashed"> </div>
      <Content>
        <SixColumns>
          <Column>
            <ColumnHeading>QUICK LINKS</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/about">About</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/career">Career</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/contact">Contact</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/terms-conditions">Terms & Condition</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/privacy-policy">Privacy Policy</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>OUR SERVICES</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/#frontend">Front End Development</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/#backend">Back End Development</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/#app">Mobile App - Hybrid & Native</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="javascript:void()">Kathmandu, Nepal 44700</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="mailto:info@coredreamsltd.com">
                  info@coredreamsltd.com
                </Link>
              </LinkListItem>
              {/* <LinkListItem>
                <Link href="tel:+977015543015">Nepal: +977 (01) 5543015 </Link>
              </LinkListItem> */}
              {/*<LinkListItem>
                <Link href="tel:+4402081332380">UK: (+44) 0208 133 2380 </Link>
              </LinkListItem> */}
              {/* <LinkListItem>
                <Link href="tel:+17186184363">USA: +1 (718) 618-4363 </Link>
              </LinkListItem> */}
              {/* <LinkListItem>
                <Link href="tel:+61390154091">AUS: (+61) 3 9015 4091 </Link>
              </LinkListItem> */}
            </LinkList>
          </Column>
        </SixColumns>

        <ThreeColRow className="copyright-section">
          {/* <div>
            <p className="mb-2">
              Our Partners <br />
            </p>
            <a href="https://360expose.com/" target="_blank" rel="noreferrer">
              <LogoImg className="footer-logo sm" src={threeLogoImage} /> &nbsp;
            </a>
            <a
              href="https://taxi-webdesign.com/"
              target="_blank"
              rel="noreferrer"
            >
              <LogoImg className="footer-logo" src={taxiLogoImage} /> &nbsp;
            </a>
          </div> */}
          <CopywrightNotice>
            &copy; {new Date().getFullYear()} Core dreams Limited. All Rights
            Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink
              href="https://www.facebook.com/coredreamsit/"
              target="_blank"
              rel="noreferrer"
            >
              <FacebookIcon />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/company/coredreams-innovations/"
              target="_blank"
              rel="noreferrer"
            >
              <LinkedInIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
