import React from "react";
import { Helmet } from "react-helmet";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustrationFullForm.js";
import ContactDetails from "components/cards/ThreeColContactDetails.js";
import ContactImage from "images/contact.svg";

const Address = tw.span`leading-relaxed`;
const AddressLine = tw.span`block`;
const Email = tw.span`text-sm mt-6 block text-gray-500`;
const Phone = tw.span`text-sm mt-0 block text-gray-500`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Helmet>
        <title>Contact | Core Dreams Innovations Pvt Ltd</title>
        <meta
          name="description"
          content="Core Dreams Innovations can be contact via email or phone 5 days a week"
        />
      </Helmet>
      <Header />
      <section className="contact-page">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h1 className="inner-main-heading text-left">
                Feel free to <span>get in touch</span> with us.
              </h1>
              <p>
                Want to get in touch? We'd love to hear from you. Here's how you
                can reach us..
              </p>
            </div>
            <div className="col-md-6">
              <img src={ContactImage} />
            </div>
          </div>
        </div>
      </section>
      <ContactUsForm />

      <Footer />
    </AnimationRevealPage>
  );
};
